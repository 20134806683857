<template>
  <div class="row">
    <div class="col-12 card">
      <h4>Аналитика Охрана труда</h4>
      <!-- <div class="row justify-content-space-between top-24">
          <div class="col-6">
            <div class="row justify-content-space-between">
              <div class="text-field col-8">
                <div class="text-field__icon">
                  <input class="text-field__search" type="search" name="search" id="search" placeholder="Поиск по таблице" value="">
                  <span class="text-field__aicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <router-link to="/reports/create"><button class="btn-primary"><span class="icon-Plus" style="left:4px;"></span></button></router-link>
              </div>
            </div>
          </div>
      </div> -->
      <div class="row">

          <table class="col-12">
            <thead>
              <tr>
                <th>Наименование</th>
                <th class="width-10">Тип</th>
                <th class="width-10">Статус</th>
              </tr>
            </thead>
            <tbody>

              <!-- <tr>
                <td>Cводные показатели эффективности системы управления охраной труда в организациях периметра (kpi)</td>
                <td>Таблица</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr> -->
              <tr>
                <td><router-link to="/analytics/3">Документарное соответствие (ОТиТБ) v1.0 (2022)</router-link></td>
                <td>Таблица/Диаграмма</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
              <tr>
                <td><router-link to="/analytics/2">Документарное соответствие (ОТиТБ) v2.0 (2023)</router-link></td>
                <td>Таблица/Диаграмма</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
              <tr>
                <td><router-link to="/analytics/1">Инспекции автопарка</router-link></td>
                <td>Таблица</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
              <tr>
                <td><router-link to="/analytics/4">Коэффициент аварийности</router-link></td>
                <td>Таблица/Диаграмма</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
              <tr>
                <td><router-link to="/analytics/5">Соответствие матбазы (ОТиТБ)</router-link></td>
                <td>Таблица/Диаграмма</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
              <tr>
                <td><router-link to="/analytics/6">Кол-во НС / серьезных аварий</router-link></td>
                <td>Таблица/Диаграмма</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="row">
            <ul class="page top-24">
              <li class="page_btn"><span class="icon-CaretLeft"></span></li>
              <li class="page_numbers active"> 1</li>
              <li class="page_numbers">2</li>
              <li class="page_numbers">3</li>
              <li class="page_numbers">4</li>
              <li class="page_numbers">5</li>
              <li class="page_numbers">6</li>
              <li class="page_dots">...</li>
              <li class="page_numbers"> 10</li>
              <li class="page_btn"><span class="icon-CaretRight"></span></li>
            </ul>
            
          </div> -->
      </div>
    </div>
  </div>
</template>