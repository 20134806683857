<template>
  <div class="row justify-content-space-between" v-if="currentUser.groups.includes('Руководитель')">
    <div class="col-12">
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'labor'}" @click="currentTab = 'labor'">Охрана труда</button>
      <!-- <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'disp'}" @click="currentTab = 'disp'">Диспетчерская</button> -->
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'fact'}" @click="currentTab = 'fact'">Завод</button>
      <!-- <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'appeal'}" @click="currentTab = 'appeal'">Жалобы на вывоз</button> -->
    </div>
  </div>
  <div v-if="currentUser.groups.includes('Руководитель')">
    <AnalyticsLabor v-if="currentTab === 'labor'"></AnalyticsLabor>
    <!-- <HomeDisp v-if="currentTab === 'disp'"></HomeDisp> -->
    <AnalyticsFactory v-if="currentTab === 'fact'"></AnalyticsFactory>
    <!-- <HomeAppeal v-if="currentTab === 'appeal'"></HomeAppeal> -->
  </div>
  <div v-else>
    <AnalyticsLabor v-if="currentUser.groups.includes('Охрана труда')"></AnalyticsLabor>
    <!-- <HomeDisp v-if="currentUser.groups.includes('Диспетчерская')"></HomeDisp> -->
    <AnalyticsFactory v-if="currentUser.groups.includes('Завод')"></AnalyticsFactory>
    <!-- <HomeAppeal v-if="currentUser.groups.includes('QR КП')"></HomeAppeal> -->
  </div>
  
</template>

<script>
import AnalyticsLabor from "@/components/Analytics/AnalyticsLabor.vue";
import AnalyticsFactory from "@/components/Analytics/AnalyticsFactory.vue";

export default {
  data() {
    return {
      title: "Справочники",
      currentTab: 'labor'
    }
  },
  components: { AnalyticsLabor, AnalyticsFactory },
  mounted() {
    this.$store.dispatch("fetchReports");
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    classActive() {
      return {
        active: this.isActive && !this.error,
        'text-danger': this.error && this.error.type === 'fatal'
      }
    }
  },
};
</script>
