<template>
  <div class="row">
    <div class="col-12 card">
      <h4>Аналитика Завод</h4>
      <div class="row">
          <table class="col-12">
            <thead>
              <tr>
                <th>Наименование</th>
                <th class="width-10">Тип</th>
                <th class="width-10">Статус</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><router-link to="/analytics/7">Простои оборудования</router-link></td>
                <td>Таблица</td>
                <td><span class="icon-Check green"></span>Активный</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</template>